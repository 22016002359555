@use "sass:map";
@use 'sass:list';

/* stylelint-disable rule-empty-line-before  */

// Spacers
$html-font-size: 16px;
$spacer-mobile-vertical: var(--gridunit);
$spacer-mobile-horizontal: var(--gridunit-small);
$spacer-tablet-vertical: var(--gridunit-large);
$spacer-tablet-horizontal: var(--gridunit);
$spacer-desktop-vertical: calc(var(--gridunit-medium) * 2);
$spacer-desktop-horizontal: var(--gridunit);

// Responsive sets

@mixin tiny { @media screen and (max-width: 239px) { @content; } }
@mixin mobile { @media screen and (max-width: 759px), screen and (max-width: 1023px) and (orientation: landscape) { @content; } }
@mixin not-mobile { @media screen and (min-width: 760px) and (orientation: portrait), screen and (min-width: 1024px) and (orientation: landscape) { @content; } }
@mixin tablet { @media screen and (min-width: 760px) and (max-width: 1023px) { @content; } }
@mixin desktop { @media all and (min-width: 1024px) { @content; } }
@mixin not-desktop { @media all and (max-width: 1023px) { @content; } }
@mixin desktop-wide { @media all and (min-width: 1281px) { @content; } }
@mixin desktop-very-wide { @media all and (min-width: 1440px) { @content; } }
@mixin desktop-ultra-wide { @media all and (min-width: 1920px) { @content; } }
@mixin not-desktop-wide { @media all and (max-width: 1280px) { @content; } }
@mixin not-desktop-very-wide { @media all and (max-width: 1439px) { @content; } }
@mixin not-desktop-ultra-wide { @media all and (max-width: 1919px) { @content; } }

@mixin default-padding {
	@include mobile { padding: 0 $spacer-mobile-horizontal $spacer-mobile-vertical $spacer-mobile-horizontal; }
	@include tablet { padding: 0 $spacer-tablet-horizontal $spacer-tablet-vertical $spacer-tablet-horizontal; }
	@include desktop { padding: 0 $spacer-desktop-horizontal $spacer-desktop-vertical $spacer-desktop-horizontal; }
}
@mixin default-margin {
	@include mobile { margin-top: $spacer-mobile-vertical; }
	@include tablet { margin-top: $spacer-tablet-vertical; }
	@include desktop { margin-top: $spacer-desktop-vertical; }

	@media print { margin-top: $spacer-desktop-vertical; }
}
@mixin block-margin {
	@include mobile { margin-top: var(--gridunit-larger); }
	@include tablet { margin-top: var(--gridunit-largest); }
	@include desktop { margin-top: var(--gridunit-largest); }

	@media print { margin-top: var(--gridunit-largest); }
}

// Clearing

@mixin clearfix {
	&:before,
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}

// hex to rgb parts

@function to-rgb($color) {
	@return red($color) + ", " + green($color) + ", " + blue($color);
}

// Scrolling

@mixin overflow($displayScrollbars: false) {
	overflow: hidden;
	@if $displayScrollbars == true {
		overflow-y: scroll;
	} @else {
		overflow-y: auto;
	}
	-ms-overflow-style: auto; -webkit-overflow-scrolling: touch; scroll-behavior: auto;
}

@mixin scrollbars($dark: false) {
	&::-webkit-scrollbar { width: 10px; height: 10px; }
	&::-webkit-scrollbar-button { width: 0; height: 0; }
	&::-webkit-scrollbar-thumb { border-radius: var(--radius-default); }
	&::-webkit-scrollbar-track { border-radius: var(--radius-default); }
	@if $dark == true {
		&::-webkit-scrollbar-thumb { background: rgba(#ffffff, 0.5); }
		&::-webkit-scrollbar-thumb:hover { background: #ffffff; }
		&::-webkit-scrollbar-thumb:active { background: #ffffff; }
		&::-webkit-scrollbar-track { background: rgba(#ffffff, 0.1); border-radius: var(--radius-default); }
		&::-webkit-scrollbar-track:hover { background: rgba(#ffffff, 0.2); }
		&::-webkit-scrollbar-track:active { background: rgba(#ffffff, 0.3); }
		&::-webkit-scrollbar-corner { background: transparent; }
	} @else {
		&::-webkit-scrollbar-thumb { background: rgba(#000000, 0.1); }
		&::-webkit-scrollbar-thumb:hover { background: #000000; }
		&::-webkit-scrollbar-thumb:active { background: #000000; }
		&::-webkit-scrollbar-track { background: rgba(#ffffff, 0.05); border-radius: var(--radius-default); }
		&::-webkit-scrollbar-track:hover { background: rgba(#ffffff, 0.1); }
		&::-webkit-scrollbar-track:active { background: rgba(#ffffff, 0.1); }
		&::-webkit-scrollbar-corner { background: transparent; }
	}
}

// Blueprint

@mixin react-transition(
	$name,
    $properties,
    $duration: 1500ms,
    $easing: var(--ui-easing),
    $delay: 0,
    $before: "",
    $after: ""
) {
	#{"#{$before}#{$after}"} { @include each-prop($properties, 2); }

	@include react-transition-phase($name, "enter", $properties, $duration, $easing, $delay, $before, $after);
	@include react-transition-phase($name, "exit", $properties, $duration, $easing, $delay, $before, $after);
}

@mixin react-transition-phase(
	$name,
    $phase,
    $properties,
    $duration: 500ms,
    $easing: var(--ui-easing),
    $delay: 0,
    $before: "",
    $after: ""
) {
	$start-index: 1;
	$end-index: 2;

	@if $phase == "exit" {
		$start-index: 2;
		$end-index: 1;
	} @else if $phase != "enter" and $phase != "appear" {
		@error "Unknown transition phase: #{$phase}. Expected appear|enter|exit.";
	}

	#{transition-name($phase, $name, $before, $after)} {
		@include each-prop($properties, $start-index);
	}

	#{transition-name(#{$phase}-active, $name, $before, $after)} {
		@include each-prop($properties, $end-index);
		transition-delay: $delay;
		transition-duration: $duration;
		transition-property: map.keys($properties);
		transition-timing-function: $easing;
	}
}

@mixin each-prop($properties, $index) {
	@each $prop, $values in $properties {
		#{$prop}: list.nth($values, $index);
	}
}

@function transition-name($phase, $name, $before, $after) {
	$full-name: "#{$before}.#{$name}-#{$phase}#{$after}";

	@if $phase == "enter" {
		@return ($full-name, transition-name("appear", $name, $before, $after));
	} @else if $phase == "enter-active" {
		@return ($full-name, transition-name("appear-active", $name, $before, $after));
	} @else {
		@return $full-name;
	}
}

// Positioning

@mixin position-all($position, $value) {
	bottom: $value;
	left: $value;
	position: $position;
	right: $value;
	top: $value;
}

// Rem unit functions

@function strip-unit($value) {
    @return $value / ($value * 0 + 1);
}
@function rem($pxValue) {
    @return #{strip-unit($pxValue) / strip-unit($html-font-size)}rem;
}
