@import "@/styles/mixins";

:import("@/components/ui/Scrollable/styles.module.scss") {
  scrollable: scrollable;
  scrollable__scroller: scrollable__scroller;
}

:import("@/components/ui/Well/styles.module.scss") {
  well: well;
}

:import("@/components/ui/LayoutOverlay/styles.module.scss") {
  layout-overlay: layout-overlay;
  size-max: size-max;
}

.block {
  flex: 1 0 auto;
  padding: 0 0 calc(var(--gridunit) * 3) 0;

  @include mobile {
    padding: 0 0 var(--gridunit) 0;
    display: grid;
    gap: 0;
    align-content: start;

    // 1216px desired width
    grid-template-columns: [max-start] var(--gridunit-small) [full-start] 1fr [limited-start leftinner-start] minmax(0, 592px) [leftinner-end] var(--gridunit-small) [rightinner-start] minmax(0, 592px) [rightinner-end limited-end] 1fr [full-end] var(--gridunit-small) [max-end];
  }
  @include not-mobile {
    display: grid;
    gap: 0;
    align-content: start;
    grid-template-columns: [max-start] var(--gridunit) [full-start] 1fr [limited-start leftinner-start] minmax(0, 592px) [leftinner-end] var(--gridunit) [rightinner-start] minmax(0, 592px) [rightinner-end limited-end] 1fr [full-end] var(--gridunit) [max-end];
  }

  @media print {
    display: block;
  }

  grid-auto-rows: min-content;

  > * {
    grid-column: limited;
  }

  .breakout {
    padding: 0 !important;
    grid-column: max !important;
    box-sizing: border-box;

    @include not-mobile {
      TABLE {
        width: unset !important;
      }
    }

    &.width-max {
      max-width: none;
      grid-column: max !important;
    }

    &.width-full {
      max-width: none;
      grid-column: full !important;
    }

    &.width-default {
      width: 100%;
      max-width: var(--content-maxwidth);
      grid-column: full !important;
      display: flex;
      flex-direction: column;
      margin-left: auto;
      margin-right: auto;
    }

    &.width-medium {
      width: 100%;
      max-width: 1016px;
      grid-column: full !important;
      display: flex;
      flex-direction: column;
      margin-left: auto;
      margin-right: auto;

      @include mobile {
        margin: 0;
      }
    }

    &.width-narrow {
      width: 100%;
      max-width: 600px;
      grid-column: full !important;
      display: flex;
      flex-direction: column;
      margin-left: auto;
      margin-right: auto;

      @include mobile {
        margin: 0;
        max-width: 100%;
      }
    }

    @include not-mobile {
      .scrollable,
      .scrollable__scroller {
        margin-left: 0 !important;
        margin-right: 0 !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
  }

  @supports (padding: max(0px)) {
    padding-left: max(0, #{'env(safe-area-inset-left)'});
    padding-right: max(0, #{'env(safe-area-inset-right)'});
  }

  // Themes

  &.theme-default {
    background-color: transparent;
  }

  &.theme-lightest {
    --site-bg: var(--color-white);
    background-color: var(--color-white);
  }

  &.theme-lighter {
    background: var(--color-neutral-50);
  }

  &.theme-light {
    --site-bg: var(--color-primary-300);
    background-color: var(--color-primary-300);
    color: var(--color-white);
  }

  &.theme-medium {
    --site-bg: var(--color-primary-500);
    background-color: var(--color-primary-500);
    color: var(--color-white);
  }

  &.theme-dark {
    --site-bg: var(--color-primary-850);
    background-color: var(--color-primary-850);
    color: var(--color-white);
  }

  &.theme-category01 {
    --site-bg: #CEE1FD;
    background-color: var(--site-bg);
  }

  &.theme-category02 {
    --site-bg: #FDD1CE;
    background-color: var(--site-bg);
  }

  &.theme-category03 {
    --site-bg: #FDEACE;
    background-color: var(--site-bg);
  }

  &.theme-category04 {
    --site-bg: #F5FB9D;
    background-color: var(--site-bg);
  }

  // Widths

  &.width-default {
    @include not-mobile {
      grid-template-columns: [max-start] var(--gridunit) [full-start] 1fr [limited-start leftinner-start] minmax(0, 592px) [leftinner-end] var(--gridunit) [rightinner-start] minmax(0, 592px) [rightinner-end limited-end] 1fr [full-end] var(--gridunit) [max-end];
    }
  }

  &.width-narrow {
      @include not-mobile {
        padding: calc(var(--gridunit-large) - 1em) 0 var(--gridunit-large) 0 !important;
        grid-template-columns: [max-start] var(--gridunit-large) [full-start] 1fr [limited-start leftinner-start] minmax(0, 300px) [leftinner-end] var(--gridunit) [rightinner-start] minmax(0, 300px) [rightinner-end limited-end] 1fr [full-end] var(--gridunit-large) [max-end];
      }
    }

  &.width-medium {
      @include not-mobile {
        grid-template-columns: [max-start] var(--gridunit-large) [full-start] 1fr [limited-start leftinner-start] minmax(0, 400px) [leftinner-end] var(--gridunit) [rightinner-start] minmax(0, 400px) [rightinner-end limited-end] 1fr [full-end] var(--gridunit-large) [max-end];
      }
    }

  &.width-article {
    @include mobile {
      min-width: 0;

      TABLE {
        min-width: 100%;
      }
    }
    @include not-mobile {
      // 592px desired width
      grid-template-columns: [max-start] var(--gridunit) [full-start] 1fr [limited-start leftinner-start] minmax(0, calc((592px - var(--gridunit)) / 2)) [leftinner-end] var(--gridunit) [rightinner-start] minmax(0, calc((592px - var(--gridunit)) / 2)) [rightinner-end limited-end] 1fr [full-end] var(--gridunit) [max-end];

      TABLE {
        min-width: 1016px;
      }
    }

    > .well { margin-top: var(--gridunit-large); width: 100%; max-width: var(--content-maxwidth); grid-column: full !important; margin-left: auto; margin-right: auto; }
  }

  &.width-max { grid-template-columns: [max-start full-start limited-start leftinner-start] 1fr [leftinner-end rightinner-start] 1fr [rightinner-end limited-end full-end max-end]; }

  &.width-full {
    grid-template-columns: [max-start] var(--gridunit-small) [full-start limited-start leftinner-start] 1fr [leftinner-end rightinner-start] 1fr [rightinner-end limited-end full-end] var(--gridunit-small) [max-end];
    @include not-mobile {
      grid-template-columns: [max-start] var(--gridunit) [full-start limited-start leftinner-start] 1fr [leftinner-end rightinner-start] 1fr [rightinner-end limited-end full-end] var(--gridunit) [max-end];
    }
  }

  &.width-search {
      @include not-mobile {
        grid-template-columns: [max-start] var(--gridunit) [full-start] 1fr [limited-start] 696px [limited-end] var(--gridunit) [sidebar-start] minmax(0, 488px) [sidebar-end] 1fr [full-end] var(--gridunit) [max-end];
      }
    }

  &.compact {
    padding-bottom: var(--gridunit) !important;
    padding-top: 0 !important;
  }

  // Exceptions

  @at-root .layout-overlay & {
    padding: var(--gridunit-large) 0;
    @include mobile { padding: 0 0 var(--gridunit) 0; }
  }
  @at-root .layout-overlay.size-max & { display: flex !important; flex-direction: column; padding: 0 var(--gridunit) var(--gridunit) var(--gridunit); }
}