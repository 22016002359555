@import "@/styles/mixins";

.heading-with-tools {
  @include block-margin; display: flex; flex-direction: row; flex-wrap: nowrap; gap: var(--gridunit); position: relative; page-break-after: avoid; page-break-inside: avoid;

  H1, H2, H3, H4, H5, H6 { margin: 0; }

  &:has(H4) { margin-top: var(--gridunit); }

  &__main { flex: 0 1 700px; }

  &__pre { text-transform: uppercase; letter-spacing: 0.04em; color: var(--color-highlight); }

  &__description {
    margin: 1em 0 0 0; font-size: 20px; line-height: 28px;

    P:first-child { margin: 0; }
  }

  &__animation {
    height: 2px; flex: 0 0 auto; align-self: flex-end;

    > * { transform: translate(0, calc(-100% + 20px)); margin-bottom: -20px; }

    @include mobile {
      position: absolute; right: 0; bottom: 100%; height: auto;

      > * { margin: 0 0 -40px 0; transform: none; }
    }
  }

  &__buttons {
    flex: 0 0 auto; display: flex; flex-direction: row; flex-wrap: nowrap; align-items: flex-end; gap: var(--gridunit-tiny); margin-left: auto;
  }

  &__addon {
    flex: 0 0 auto; margin-left: auto;

    @include not-mobile {
      > * { margin-top: 0 !important; }
    }
  }

  @include mobile {
    display: block;

    &__buttons { margin-top: var(--gridunit-small); justify-content: flex-end; }
  }
}
