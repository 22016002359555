@import "@/styles/mixins";

// General display related

.overlayopen {
  pointer-events: none; overflow: hidden; -webkit-overflow-scrolling: auto; -ms-overflow-style: none; position: relative;

  BODY { padding-right: 0 !important; overflow: initial !important; }

  :global {
    #__next {
      position: relative; height: 100%; overflow: hidden; overflow-y: scroll; -webkit-overflow-scrolling: auto; scroll-behavior: auto; pointer-events: none;
      @include not-desktop {
        position: absolute; height: auto; left: 0; right: 0; top: 0; bottom: 0; overflow-y: scroll; -webkit-overflow-scrolling: auto; scroll-behavior: auto; pointer-events: none;
      }
    }
  }

  .overlay .overlayholder { position: absolute; box-sizing: border-box; }
}

.overlay {
  display: inline;

  &:not(.overlay-open) { pointer-events: none; }

  &:focus { outline: none; }

  .overlayholder {
    @include position-all(fixed !important, 0); margin-right: calc(100% - 100vw) !important;
    @include not-desktop { position: absolute; }
  }
}

// Default layout animations

.overlay-default {
  $trans1: (transform: (translateY(20px), translateX(0)), opacity: (0, 1));
  $trans2: (opacity: (0, 0.8));

  height: 100%; overflow: hidden; position: relative; z-index: 101;

  @include react-transition("overlay", $trans1, var(--ui-speed), var(--ui-easing), $before: ":global ", $after: " .overlay__content");
  @include react-transition("overlay", $trans2, var(--ui-speed), var(--ui-easing), $before: ":global ", $after: " .overlay__backdrop");

  &__holder { height: 100%; overflow: hidden; box-sizing: border-box; }

  &__content {
    flex: 0 0 auto; margin: auto; height: 100%; overflow: hidden;

    > *:first-child { flex: 0 0 auto; }
  }

  &__close {
    position: absolute; right: 32px; top: 32px; z-index: 1; width: 40px; height: 40px; border: none; padding: 0; margin: 0; background-color: transparent; box-shadow: none; cursor: pointer; color: var(--color-white);

    svg { position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); width: 100%; height: 100%; }

    path { fill: currentColor; }
    @include mobile { display: none; }
  }

  &__scroller {
    flex: 0 0 auto; height: 100%; position: relative; box-sizing: border-box; display: flex; flex-direction: column;
    overflow: hidden; @include overflow(false);
  }
}

// Mobile menu layout

.overlay-mobilemenu {
  $trans1: (transform: (translateX(-20px), translateX(0)), opacity: (0, 1));
  $trans2: (opacity: (0, 0.8));

  height: 100%; overflow: hidden; position: relative; z-index: 9;

  @include react-transition("overlay", $trans1, var(--ui-speed), var(--ui-easing), $before: ":global ", $after: " .overlay__content");
  @include react-transition("overlay", $trans2, var(--ui-speed), var(--ui-easing), $before: ":global ", $after: " .overlay__backdrop");

  &__overlayholder {
    top: 66px !important;

    @include not-mobile { top: 72px !important; }
  }

  &__holder { height: 100%; overflow: hidden; box-sizing: border-box; }

  &__content {
    flex: 0 0 auto; margin: auto; height: 100%; overflow: hidden;
    display: flex; flex-direction: column;

    > *:first-child { flex: 0 0 auto; }
  }

  &__scroller {
    flex: 1 1 auto; position: relative; box-sizing: border-box; display: flex; flex-direction: column;
    overflow: hidden; @include overflow(false);
  }

  .overlay__backdrop { z-index: 8; }
}

// Mobile search layout

.overlay-mobilesearch {
  $trans1: (transform: (translateY(20px), translateY(0)), opacity: (0, 1));
  $trans2: (opacity: (0, 0.8));

  height: 100%; overflow: hidden; position: relative; z-index: 101;

  @include react-transition("overlay", $trans1, var(--ui-speed), var(--ui-easing), $before: ":global ", $after: " .overlay__content");
  @include react-transition("overlay", $trans2, var(--ui-speed), var(--ui-easing), $before: ":global ", $after: " .overlay__backdrop");

  &__overlayholder {
    top: 0 !important;

    @include not-mobile { top: 0 !important; }
  }

  &__holder { height: 100%; overflow: hidden; box-sizing: border-box; }

  &__content {
    flex: 0 0 auto; margin: auto; height: 100%; overflow: hidden;

    > *:first-child { flex: 0 0 auto; }
  }

  &__scroller {
    flex: 0 0 auto; height: 100%; position: relative; box-sizing: border-box; display: flex; flex-direction: column;
    overflow: hidden; @include overflow(false);
  }
}

// States

.overlay-open {
  pointer-events: auto;

  .overlay__content { pointer-events: auto; }
}

.overlay__backdrop {
  @include position-all(absolute, 0);
  z-index: 100; background-color: var(--backdrop-bg); overflow: auto; user-select: none;

  &:focus { outline: none; }
}

.overlay__content {
  &:focus { outline: none; }
}

