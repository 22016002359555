.icon-preview {
  overflow: hidden; margin: 10px -10px 0 0;

  > DIV {
    float: left; width: 220px; margin: 10px 10px 0 0; font-size: 11px; border: 1px solid rgba(#000000, 0.1); border-radius: var(--radius-default); box-sizing: border-box; padding: 10px; text-align: center; background-color: rgba(#000000, 0.05);

    svg { height: 50px; max-width: 150px; }
  }
}

.icon {
  &.intent-danger {
    path, circle { fill: var(--color-danger); }
  }

  &.intent-success {
    path, circle { fill: var(--color-success); }
  }

  &.intent-warning {
    path, circle { fill: var(--color-warning); }
  }

  &.intent-discrete {
    opacity: 0.5;
  }
}
